/***********************/
/** LINKS AND BUTTONS **/
/***********************/

/** DISABLE DOUBLE CLICKING **/
.disable-double-click {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

/** BUTTON GROUP **/


/** LINKS **/
a {
    /* Base link style */
    color: $link-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $link-color-hover;
        text-decoration: none;
        transition: $link-hover-delay;
    }

    /* Gray link */
    &.gray-link {
        color: $gray-600;

        &:hover {
            color: $text-color;
            transition: $link-hover-delay;
        }
    }

    /* Force it to look like a link */
    &.link {
        color: $link-color !important;
        text-decoration: none !important;
        cursor: pointer;

        &:focus {
            outline: none !important;
        }

        &:hover {
            color: $link-color-hover !important;
            text-decoration: none !important;
            transition: $link-hover-delay;
        }
    }
}

.div-link {
    color: $link-color !important;
    text-decoration: none !important;
    cursor: pointer;
}

.hover-div-link:hover {
    color: $link-color !important;
    text-decoration: none !important;
    cursor: pointer;
}






