/** CUSTOM MIXINS **/

/** BOX SHADOW **/
@mixin boxShadow($h: 0px, $v: 0px, $b:2px, $s:0, $color:$box-shadow-color) {
    -webkit-box-shadow: $h $v $b $s $color;
    -moz-box-shadow: $h $v $b $s $color;
    box-shadow: $h $v $b $s $color;
}
//Accepts hex color, returns rgb with desired opacity
@mixin backgroundOpacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

//Generate two-color-gradient
@mixin backgroundStriped($color1:$background-color, $color2:$gray-200, $angle:-45deg) {
    background: $color1; //the fallback
    background:repeating-linear-gradient($angle, $color1 0, $color1 7px, $color2 7px, $color2 12px);
}

//Calculate custom modal height
@mixin modalMaxHeight($customElementHeight:0px) {
    //Account for outer modal offset, header height, footer height and modal container padding, plus any custom elements
    max-height: calc(92vh - 55px - 46px - 40px - #{$customElementHeight});
}