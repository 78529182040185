
.list-unstyled {
    padding-left:0;
    list-style:none;
}

.default-text {
    color: $text-color-muted;
}

/* ICONS */

//Extend these two classes to control icon display in various contexts
.icon-before {
    margin:0 5px 0 0;
}
.icon-after {
    margin:0 0 0 5px;
}

.blue-pointer {
    color: $blue-light;
    cursor: pointer;
}

