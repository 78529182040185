/** GLOBAL TABLE AND GRID STYLES **/

/** CELL ALIGNMENT **/
// Add classes to a table to control the alignment of each column. Useful for simple tables not using ag-grid. Might be useful in some cases for those as well.
//   -- By default, all table columns are left-aligned.
//   -- "tc" stands for "text-centered", and the number is the column number, counting from left to right, starting with 1
//   -- "tr" stands for "text-right"
// So to center the text in column 3, you would add the class "tc3" to the table.
// If you need to count beyond 9 columns, add the necessary styles here.

.tc1 td:nth-child(1),
.tc1 th:nth-child(1),
.tc2 td:nth-child(2),
.tc2 th:nth-child(2),
.tc3 td:nth-child(3),
.tc3 th:nth-child(3),
.tc4 td:nth-child(4),
.tc4 th:nth-child(4),
.tc5 td:nth-child(5),
.tc5 th:nth-child(5),
.tc6 td:nth-child(6),
.tc6 th:nth-child(6),
.tc7 td:nth-child(7),
.tc7 th:nth-child(7),
.tc8 td:nth-child(8),
.tc8 th:nth-child(8),
.tc9 td:nth-child(9),
.tc9 th:nth-child(9) {
    text-align: center;
}

.tr1 td:nth-child(1),
.tr1 th:nth-child(1),
.tr2 td:nth-child(2),
.tr2 th:nth-child(2),
.tr3 td:nth-child(3),
.tr3 th:nth-child(3),
.tr4 td:nth-child(4),
.tr4 th:nth-child(4),
.tr5 td:nth-child(5),
.tr5 th:nth-child(5),
.tr6 td:nth-child(6),
.tr6 th:nth-child(6),
.tr7 td:nth-child(7),
.tr7 th:nth-child(7),
.tr8 td:nth-child(8),
.tr8 th:nth-child(8),
.tr9 td:nth-child(9),
.tr9 th:nth-child(9) {
    text-align: right;
}

/** GRID CONTAINER **/
//(Houses the grid and it's filter panel and/or header)

.grid-mat-card {
    margin-bottom: 0 !important;
}

.grid-card-container {
    width: 100%;
    padding-top: 35px;

    .grid-container-header {
        .grid-container-title {
            i {
                cursor: pointer;
                font-size: 1.25rem;
            }
        }

        .control-buttons {
            float: right;
            margin-right: 15px;
            margin-top: 4px;

            i {
                cursor: pointer;
                margin-left: 12px;
                font-size: .9375rem;
            }

        }

        .filter-panel-container {
            padding-bottom: 25px;
            margin-bottom: -10px;
            background: white;

            .filter-card-container {
                padding-left: 0;
            }
        }


    }

    .grid-container-body {
        border: none;
        overflow: auto !important;
    }

    .grid-bottom-actions {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.grid-container {
    width: 100%;

    .grid-container-header {
        .grid-container-title {
            i {
                cursor: pointer;
                font-size: 1.25rem;
            }
        }

        .control-buttons {
            float: right;
            margin-right: 15px;
            margin-top: 4px;

            i {
                cursor: pointer;
                margin-left: 12px;
                font-size: .9375rem;
            }

        }

        .filter-panel-container {
            padding-bottom: 25px;
            margin-bottom: -10px;
            background: $background-color;
        }


    }

    .grid-container-body {
        border: none;
        overflow: auto !important;
    }
}

.filtered-text {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.38);
}

/** SHOW BORDER **/
//(this should mostly not be used, now that grids are no longer being collapsed)
.show-border {
    .grid-container-header {
        border-bottom: 0;
        color: $text-color;
        border-radius: 4px;

        .filter-panel-container {
            box-shadow: none !important;
            border-radius: 2px;
            background-color: $white;
        }

        & .collapse .filter-panel-container .filters {
            box-shadow: none !important;
            border-radius: 2px;
            border: 1px;
            border-style: outset;
        }

    }

    .grid-container-body {
        padding: 15px;
        border-radius: 0 0 4px 4px;
        margin-left: 5px;
        margin-right: 5px;
    }
}


.grid-command-icon {
    font-size: 1.0625rem;
    color: $text-color-muted;
    margin: 5px;
    padding: 0;
    border: none;
    background: none;
    display: inline;
}

.grid-card-control-button-panel {
    position: absolute;
    right: 25px;
    top: 5px;

    .cancel-button {
        margin-left: 2px;
    }
}

.grid-control-button-panel {
    position: absolute;
    right: 25px;
    top: -45px;
    padding: 2px 2px 2px;

    .cancel-button {
        margin-left: 2px;
    }
}

.editable-grid {
    z-index: 10;
}

.aggregate-total-panel {
    display: flex;

    .aggregate-column {
        flex: 1;
        text-align: right;

        .control-group {
            width: 100%;
            padding-right: 13px;
            padding-bottom: 17px;
            margin-top: -12px;

            label {
                margin: 20px;
                font-weight: 600;
                display: inline-flex;
            }

            .field {
                width: 150px !important;
                display: inline-flex;
            }
        }
    }
}

.report-grid-header {
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 28px;
    margin-top: -12px;
    padding-top: 22px;
    padding-bottom: 12px;
}

.ag-theme-balham .ag-popup-child:not(.ag-tooltip-custom) {
    box-shadow: 3px 3px 6px rgb(0 0 0 / 30%);
}
