/** OVERRIDING/SETTING VARIOUS ICONS **/
//(App uses Font Awesome globally. This is for specific additional icons, or injecting icons into components like ag-grid)


/* AG-GRID */
$icon-font-family: "Font Awesome 5 Free";

$ag-icon-aggregation: "\f247";
$ag-icon-arrows: "\f0b2";
$ag-icon-asc: "\f062";
$ag-icon-cancel: "\f057";
$ag-icon-chart: "\f080";
$ag-icon-checkbox-checked: "\f14a";
$ag-icon-checkbox-indeterminate: "\f146";
$ag-icon-checkbox-unchecked: "\f0c8";
$ag-icon-color-picker: "\f576";
$ag-icon-column: "\f142";
$ag-icon-columns: "\f0db";
$ag-icon-contracted: "\f146";
$ag-icon-copy: "\f0c5";
$ag-icon-cross: "\f00d";
$ag-icon-cut: "\f0c4";
$ag-icon-data: "\f1c0";
$ag-icon-desc: "\f063";
$ag-icon-expanded: "\f0fe";
$ag-icon-eye-slash: "\f070";
$ag-icon-eye: "\f06e";
$ag-icon-filter: "\f0b0";
$ag-icon-first: "\f100";
$ag-icon-grip: "\f58e";
$ag-icon-group: "\f5fd";
$ag-icon-indeterminate: "\f06e";
$ag-icon-last: "\f101";
$ag-icon-left: "\f060";
$ag-icon-loading: "\f110";
$ag-icon-maximize: "\f2d0";
$ag-icon-menu: "\f0c9";
$ag-icon-minimize: "\f2d1";
$ag-icon-minus: "\f068";
$ag-icon-next: "\f105";
$ag-icon-none: "\f338";
$ag-icon-not-allowed: "\f05e";
$ag-icon-paste: "\f0ea";
$ag-icon-pin: "\f276";
$ag-icon-pivot: "\f074";
$ag-icon-plus: "\f067";
$ag-icon-previous: "\f104";
$ag-icon-radio-button-off: "\f111";
$ag-icon-radio-button-on: "\f058";
$ag-icon-right: "\f061";
$ag-icon-save: "\f0c7";
$ag-icon-small-down: "\f107";
$ag-icon-small-left: "\f104";
$ag-icon-small-right: "\f105";
$ag-icon-small-up: "\f106";
$ag-icon-tick: "\f00c";
$ag-icon-tree-closed: "\f105";
$ag-icon-tree-indeterminate: "\f068";
$ag-icon-tree-open: "\f107";

.icon-button-fa {
    padding: 5px;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    border-radius: 4px;
}

.icon-button-fa.disabled {
    color: gray;
    cursor: default;
}

.icon-button-fa:hover {
    background: rgba(255, 255, 255, .5);
    transition: opacity .2s;
}
