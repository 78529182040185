@import "../styles/colors";

mat-card {
    padding: 4px 25px;
    margin-bottom: 1em;

    .mat-mdc-card-title {
        border-bottom: solid 1px #e8ebf1;
        display: flex;
        justify-content: space-between;
        padding: 0;
        font-weight: $font-weight-bold; // needs to move
        font-size: $font-size-med-lg; // needs to move
        margin-bottom: 12px;

        a {
            font-size: 1rem; // needs to move
        }

        i {
            font-weight: 900; // needs to move
        }
    }

    .mat-mdc-card-content {
        padding: 6px 0;
    }

    .card-edit-form-button-group {
        text-align: right;
        margin-top: 15px;

        button {
            margin-left: 10px;
        }
    }
}

.read-only-form {
    .mat-mdc-form-field {

        .mat-mdc-form-field-wrapper, // Commented this out due to form scrunching
        .mat-mdc-form-field-imitation {
            padding-bottom: 0;
        }
    }

    a.card-icon {
        float: right;
        font-size: $font-size-base;
    }

    a.view-card-title-icon {
        position: absolute;
        top: 5px;
        right: 25px;
        font-size: $font-size-base;
    }

    mat-mdc-card-title:not(.gtn-template-card-title) {
        font-weight: $font-weight-bold;
        font-size: $font-size-med-lg;
        border-bottom: solid 1px $border-color;
        margin: 0 -10px 15px;
        padding: 0 35px 3px 10px;
        position: relative;

        a.card-title-icon {
            position: absolute;
            top: 0;
            right: 10px;
            font-size: $font-size-base;
        }
    }

    mat-mdc-card-content {
        font-size: $font-size-base;

        gtn-sponsor-name {
            display: block;
            width: 100%;

            .return {
                display: inline-block;
                margin-bottom: 9px;
            }

            a {
                font-size: 16px !important;
                color: $black;
                font-weight: $font-weight-bold;
            }

            i {
                font-size: 16px !important;
            }
        }

        .portal-link {
            margin-top: -10px;
        }

        .none-value-weight {
            font-weight: $font-weight-light;

            & .mat-mdc-input-element {
                font-weight: $font-weight-light !important;
            }
        }

        label {
            margin: 0;
            padding: 0;
        }

        ul {
            list-style-type: none;
            list-style: none;
            padding: 0;
            font-weight: $font-weight-bold;
            margin-top: -7px;
            margin-bottom: 9px;

            .close-top-margin {
                margin-top: -5px;
            }

            li {
                padding: 0;
                margin: 0;
            }
        }
    }
}

// data field
.data-field {
    font-weight: $font-weight-bold;
    margin-top: -7px;
    margin-bottom: 8px;
}

//tabs
.gtn-tabs.mat-mdc-tab-group {

    .mdc-tab.mdc-tab {
        letter-spacing: normal;
        font-size: 16px;
        padding: 0 50px;
    }

    .mdc-tab.mdc-tab--active {
        background-color: $white !important;
        font-size: 16px;
        font-weight: 500;
    }

    .mat-mdc-tab-body-content {
        overflow: hidden !important;
        padding: 0 20px;
    }

    .mat-mdc-tab-body-content,
    .mat-mdc-tab-label-active {
        background-color: $white !important;

    }

    .mat-mdc-tab-label:hover {
        background-color: $white !important;
    }

    .mat-mdc-tab-header {
        margin: 0 !important;
    }

    .mat-mdc-tab-body-content {
        .mat-mdc-card:not([class*='mat-elevation-z']) {
            box-shadow: none !important;
        }

        .mat-mdc-card {
            padding: 16px !important
        }
    }

    .mat-mdc-tab-labels {
        .mat-ripple.mat-mdc-tab-label-active.mat-focus-indicator {
            color: $blue-primary !important;
            border-bottom: 3px solid $blue-primary !important;
        }
    }

    .mat-ink-bar {
        background-color: $blue-primary !important;
    }

    .validation {
        right: 30px !important;
    }
}


