/** HEADERS **/

h1,h2,h3,h4,h5,h6 {
    position:relative; //So can guarantee being able to position child elements within any header
}

h1 {
    margin:40px 0 30px;
    color:#1D4681;
}

.page-top-header {
    margin-top:0 !important;
    padding-top:0 !important;

    .right-menu {
        float: right;
        margin-right: 15px;

        .notification-icon-wrapper {
            margin-top: -6px;
        }
    }

    h2 {
        font-weight: 500;
        font-size: 20px;
        margin-top: 15px;
    }
}
