/** FORM-SPECIFIC VARIABLES **/
//Only set variables for properties that will be used in multiple places and always need to be identical

//$form-control-max-width: 420px;
@import "colors";

$form-control-max-width: 100%;

/** FORM LAYOUT **/

/** FORM CONTROLS **/

/* Default settings */

.gtn-label {
    //Make a normal label display consistent with mat-label
    display: block;
    color: rgba(0, 0, 0, 0.54); //From Material color palette. TODO: replace with Material variable
    font-size: .75rem; //Material does this with a translate
    margin-bottom: 0; //Cancel BS4 reboot margin
    line-height: 18px;
}

/* Field sizing utilities. Works with col-classes to set fixed width on elements */
.field-type-two-digit,
.field-type-three-digit {
    max-width: 100px;
}

.field-type-four-digit {
    max-width: 112px;
}

.field-type-five-digit {
    max-width: 124px;
}

.field-type-six-digit {
    max-width: 136px;
}

.field-type-seven-digit {
    max-width: 148px;
}

.field-type-eight-digit {
    max-width: 160px;
}

.field-type-nine-digit {
    max-width: 172px;
}

.field-type-ten-digit {
    max-width: 184px;
}

.field-type-date {
    max-width: 200px;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.list-header {
    border-bottom: solid 1px $gray-300;

    .row {
        padding: 15px 0 15px 40px;
    }
}

.list-control-buttons {
    float: right;
    margin-right: 15px;
    margin-top: 4px;

    i {
        cursor: pointer;
        margin-left: 12px;
        font-size: .9375rem;
    }
}

.section-header {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 2px;
    border-bottom: solid 1px #dee2eb;
    font-weight: 700;
}

.list-label {
    font-size: 12px;
    color: $gray-700;
    margin-top: 0;
    margin-bottom: 0;
}

.list-label-blue {
    font-size: 12px;
    font-weight: $font-weight-bold;
    color: $blue-primary;
    margin-top: 0;
}

.data-field-light {
    font-size: 14px;
    margin-top: -7px;
    margin-bottom: 8px;
}

.data-field {
    font-weight: $font-weight-bold;
    margin-bottom: 8px;
}

.mat-child-card > mat-card {
    padding-bottom: 0 !important;
    margin-top: -2px;
    background-color: #e0e7ef;
    padding-top: 2px !important;
}

/** POSITIONING UTILITIES **/

.combined-control-wrap {
    position: relative; //Used when two controls need to be visually positioned relative to each other
    max-width: $form-control-max-width;
}


/* Put radios and checkboxes into columns. They will column up in whatever width is available */
.control-column {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & > * {
        flex: 0 0 240px;
        margin: 0;
        max-width: 100%;
    }
}

/* Global styling */

.currency {
    input {
        text-align: right;
    }
}


/** SIMPLE CHECKBOX -- currently only used on timeline **/
.simple-checkbox {
    position: absolute;
    opacity: 0;

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: -4px;
        width: 18px;
        height: 18px;
        background: white;
        border: 1px solid $border-color;
    }

    &:hover + label:before {
        background: $green-primary;
    }

    &:disabled + label {
        color: $gray-500;
        cursor: auto;

        &:before {
            box-shadow: none;
            background: $white;
            border: $gray-100;
        }
    }

    &:checked {
        & + label:before {
            background: $green-primary;
        }

        & + label:after {
            content: '';
            position: absolute;
            left: 3.5px;
            top: 8px;
            background: $white;
            width: 3px;
            height: 3px;
            box-shadow: 2px 0 0 $white, 4px 0 0 $white, 4px -2px 0 $white, 4px -4px 0 $white, 4px -6px 0 $white, 4px -8px 0 $white;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
