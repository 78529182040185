@import 'assets/styles/colors';

.mat-mdc-form-field { // (inputs, dropdowns)
    width: 100%;
    margin: 0;
    display: inherit;

    .mat-mdc-form-field-infix {
        letter-spacing: normal;
    }

    &.gtn-read-only {
        .mdc-floating-label.mat-mdc-floating-label {
            color: rgba(0, 2, 5, 0.608) !important;
        }

        .mdc-line-ripple {
            display: none;
        }

        .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above:not([disabled]) {
            // This resolves the issue around the input label transforming from selected to not selected
            //transform: translateY(-.50em) scale(1);
        }

        .mat-mdc-select-arrow-wrapper {
            // Layout of mat-mdc-select-arrow-wrapper (moved down to be inline with label)
            display: none;
        }

    }

    // causes issue for datepicker
    //.mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    //    top: 8px
    //}

    .mdc-text-field,
    .mat-mdc-text-field-wrapper {
        padding: 0;
    }

    //// Manipulate initial background color
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }

    // Manipulate background color on hover and focus
    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }

    // Layout of label distance from mdc-line-ripple
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 37px;
    }

    .override-mdc-styles .mat-mdc-select-arrow-wrapper {
        // Layout of mat-mdc-select-arrow-wrapper (moved down to be inline with label)
        transform: none;
    }

    .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above:not([disabled]) {
        // This resolves the issue around the input label transforming from selected to not selected
        transform: translateY(-2em) scale(0.75);
    }
}

// dropdown and searches use this pane
.cdk-overlay-pane { // dropdown
    // ToDo: Bug - Test in Contacts > Individuals > General > Edit > Phone number > Country Code - Icon and filter are not aligned when shrinking of form (minimize and expand ide-nav to see problem.
    // issue could be one of these, phone number row does not give enough width to the container which causes the inner material to squish together.
    // could be we haven't set the component the be responsive enough within in container?
    .mat-mdc-option.mdc-list-item {
        letter-spacing: normal;
    }

    .mat-mdc-menu-panel {
        .mat-mdc-menu-content {
            padding: 0;

            .mat-mdc-menu-item {
                padding: 12px;

                .mat-mdc-menu-item-text {
                    font-size: 14px;
                }
            }
        }
    }

    .mat-mdc-menu-item-text {
        font-size: 14px;
    }

    div.mat-mdc-select-panel {
        padding: 0; // removing padding above and below each mat-option from the dropdown

    }

    div.mat-mdc-autocomplete-panel { // removing padding above and below each mat-option from the autocomplete
        padding: 4px 0 0 0; // removing padding above and below each mat-option from the dropdown

    }

    .filter { // Dropdown filter search style changes
        width: 100%;

        .filter-icon {
            width: 15%;
            margin-left: 2%;
            margin-right: 3%;
        }

        input {
            max-width: 75%;
            border: none;
            height: 40px;
            outline: none;
        }
    }
}

//dropdown pane
.cdk-overlay-container {
    .mat-mdc-option {
        letter-spacing: normal;
        font-size: 14px;
        min-height: 40px;
        line-height: 40px;

        .sub-name {
            line-height: 18px;
            margin-top: -12px;
        }
    }
}

//dropdown controls
.mat-mdc-list-item-unscoped-content { // ToDo: Can find the example of this @ Documents > Tax Practice > Solutions > Document Details > Versions
    // Issue: The grid look has disappeared
    display: flex;
    align-items: center;
}

.mdc-list-item__content { // ToDo: Can find the example of this @ Documents > Tax Practice > Solutions > Document Details > Versions
    .mdc-list-item__primary-text {
        white-space: normal; // this is to allow the file name to wrap and not overlap next column
    }
}

body { // ToDo: Can find the example of this @ Documents > Tax Practice > Solutions > Document Details > Versions
    .mdc-list-item { // ToDo: added this to conform to previous style
        padding: 0 10px;
    }
}

//checkbox control
.mat-mdc-checkbox { // ToDo: this change can be seen in Contacts > Companies > Relationships > Companies > Edit Relationship - the 'is child' label to the right fix
    width: max-content;
}


// search box

.gtn-search-box {
    $searchWidth: 250px;
    $rightMargin: 0px;

    &.gtn-nexus-search {
        $searchWidth: 400px;
        $rightMargin: 20px;
    }

    &.mat-mdc-form-field {
        padding-top: .75em;

        .mat-mdc-text-field-wrapper {
            margin-bottom: 6px !important;
        }

        .mat-mdc-form-field-infix {
            //Change default Material look to be more traditional search box look
            width: $searchWidth;
            font-size: 1rem;
            line-height: 1.4;
            height: 30px !important;
            border: none !important;
            margin-top: 10px;
            margin-right: $rightMargin;

            .mdc-text-field {
                height: 34px !important;
            }

            input {
                background-color: white;
                border-radius: 4px;
                height: 34px !important;
                padding-left: 4px;
            }

            input::placeholder {
                font-weight: normal !important;
                color: $gray-700;
            }

            &:active,
            &:focus {
                outline: none;
                border: none;
            }
        }

        .mdc-line-ripple {
            display: none !important;
        }
    }
}

.gtn-control-error-message {
    position: absolute;
    width: 100%;
    font-size: 75%;
    margin-top: 0.66666667em;
    top: 42px;

    span {
        width: 100%;
        display: block;
    }
}
