/** MODALS **/

.modal-content {
    //Outside of flow so it can be easily overridden by maxModalHeight() mixin
    @include modalMaxHeight();
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $overlay-zindex;
    @include backgroundOpacity($black, .7);
    width: 100%;
    height: 100%;
    transition: opacity .2s;
    opacity: 0;

    &.overlay-shown {
        opacity: 1;
    }
}

.modal-content {

    .list-label {
        margin: 0;
        padding: 0;
    }

    .data-field {
        font-weight: 700;
        margin-top: -7px;
        margin-bottom: 8px;
    }
}



#modal-container {
    width: 100%;
    z-index: $modal-container-zindex;
    position: fixed;
    top: 4vh;

    &.modal-hidden {
        transform: translateX(-100%);
    }

    &.modal-shown {
        transform: translateX(0);
    }

    .modal-wrapper {
        @include boxShadow(0, 0, 2, 1);
        background: #fff;
        width: 400px; //default small modal
        max-width: 80% !important;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        border-radius: 4px;
        padding: 20px;
        position: relative;

        gtn-validation {
            text-align: right;

            .validation {
                right: 25px;
            }
        }

        //for when you need the modal to be wider
        &.modal-wide {
            width: 80% !important;
        }

        &.modal-medium-wide {
            width: 50% !important;
        }

        &.modal-medium {
            width: 41% !important;
        }

        &.modal-small-wide {
            width: 33% !important;
        }

        .modal-header-container {
            width: 100%;
            background-color: $white;
            border-bottom: 1px solid $border-color;
            padding: 0 10px 10px;
            position: relative;

            .single-header-text {
                font-size: 20px;
                font-weight: bold;
            }

            .main-header-text {
                font-size: 20px;
                font-weight: bold;
            }

            .sub-header-text {
                font-size: 15px;
            }

            button.close {
                color: $white !important;
                background: transparent;
                border: none;
                opacity: 0.6;
                font-size: 15px;
                text-shadow: none;
                position: absolute;
                right: 5px;
                top: 5px;

                &:focus {
                    outline: 0;
                }

            }
        }

        .modal-content {
            width: 100%;
            border-radius: 0 !important;
            padding: 30px 25px;
            border: none;
            box-shadow: none;
            overflow-y: auto;
        }

        .gtn-modal-footer {
            text-align: right;
            padding: 15px 20px;
            margin: 0 -20px -20px;
            @include boxShadow(inset, 1px, 0, 6px);
            background: $background-color;

            button {
                margin-left: 10px;
            }
        }
    }
}
