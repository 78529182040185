/** FILE FOR MANAGING GLOBAL Z-INDEXES IN ONE PLACE **/


/** FIXED PAGE HEADER | Below all chrome elements **/
$fixed-page-header-zindex:          899;

/** FIXED FOOTER | Has to be above main content but below fixed header **/
$footer-zindex:                     900;

/** FORM BUTTON GROUP | Has to be above Footer **/
$form-button-group-zindex:          1000;

/** FIXED HEADER | Has to be above main content and footer **/
$moduleNavbar-zindex:               1020 !important; //Below main header so overlaps work
$zindex-fixed:                      1030; //Default BS4 value

/** MATERIAL GLOBAL OVERLAY | Has to be above all chrome elements, but below modal **/
.cdk-global-overlay-wrapper {
    z-index:                        1100;
}

/** MODAL | Has to be above all chrome elements **/
$overlay-zindex:                    1150; //Above chrome but below modal container
$modal-container-zindex:            1200; //Above overlay

/** MATERIAL OVERLAY CONTAINER | Has to be above modal **/
.cdk-overlay-container {
    z-index:2000;
}

/** SPINNER | Has to be above everything **/
$spinner-container-zindex:          9998;
$spinner-message-container-zindex:  1000; //Current value; may need to change after seeing it in use