
/** LAYOUT GRID CUSTOMIZATIONS **/

//Standard left sidebar layout
.gtn-sidebar:not(.col-auto) {
    @extend .col-md-4;
    @extend .col-lg-3;
    transition: all .3s;

}
.gtn-main-content {
    @extend .col-md-8;
    @extend .col-lg-9;
    transition: all .3s;
}


//Standard sidenav layout
.gtn-sidenav-row {
    min-height:calc(100vh - #{$standard-height-calc-subtraction} - #{$page-title-height});
}
.gtn-sidenav {
    @extend .col;
    // @extend .col-sm-4;
    // @extend .col-md-3;
    // @extend .col-lg-2;

    //Sets a couple of fixed widths
    max-width:250px;
    min-width:250px;
    padding-right:0;

    mat-nav-list {
        display:flex;
        flex-direction:column;
        height:100%;

        .sidenav-button {
            @extend .col;
        }
    }
}
.gtn-main-content-with-sidenav {
    @extend .col; //Expands to fill the rest of the horizontal space after sidenav max-width reached
    padding-left:0;

    //Same height as sidenav
    min-height:100%;

    width: 80% !important;
}