/** VARIABLES FOR REUSE IN OTHER SCSS FILES. IMPORTS _colors, _z-index and _mixins SO OTHER SCSS FILES ONLY NEED TO IMPORT _variables **/

$nav-height: 60px;


/** SPACING **/
$component-margin-bottom: 30px;

/** FONT FAMILIES **/
$font-family-serif: "Georgia", serif;
$font-family-sans-serif: "Proxima Nova", Helvetica, sans-serif;

/** INTERACTIONS **/
$link-hover-delay: 0.3s;


/** IMPORT COLORS **/
@import 'colors';

/** IMPORT Z-INDEXES **/
@import 'z-index';

/** IMPORT ICONS **/
@import 'icons';

/** IMPORT MIXINS **/
@import "_mixins.scss";

/** BS4 variable overrides **/
$grid-breakpoints: (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 1200px,
    xl: 1500px
) !default;

$grid-gutter-width: 50px;
$headings-margin-bottom: 1rem;


/** DISPLAY CONSTANTS **/
$nexus-navbar-height: 49px;
$module-navbar-height: 49px;
$navbar-bottom-margin: 25px;
$footer-height: 40px;
$footer-top-margin: 25px;
$page-title-height: 53px;

//Standard min-height calculation, accounts for two navbars and footer
$standard-height-calc-subtraction: $nexus-navbar-height + $module-navbar-height + $navbar-bottom-margin + $footer-height + $footer-top-margin;

//Standard main-content bottom padding, allows for space between bottom of content and footer
$standard-bottom-padding: $footer-top-margin;


// Testing overriding the root variables of mdc with what we want
// if commented out most likely not working, need to figure out why in order to easily override mdcs styles from here
:root {
    // override MDC's colors
    --mdc-filled-text-field-disabled-container-color: transparent;
    --mdc-filled-text-field-container-color: none;
    --mdc-outlined-card-container-elevation: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    --mdc-filled-button-label-text-color: #57688b;

    // Font-sizes
    --mdc-filled-text-field-label-text-size: 14px;

    // Font-weights
    --mdc-filled-text-field-label-text-weight: 400;

    //letter-spacing
    --mdc-filled-text-field-label-text-tracking: normal;

    //random color
    --mdc-checkbox-selected-checkmark-color: white;

    // list-item
    --mdc-list-list-item-one-line-container-height: 50px; // ToDo: Can find the example of this @ Documents > Tax Practice > Solutions > Document Details > History - it's around the header to allow room for the 'Event' and checkmark
}
