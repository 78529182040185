/** FILE FOR MANAGING COLORS IN ONE PLACE **/
/** Imported into _variables.scss, so this file should never be referenced directly. Just @import variables **/

/* MAIN COLORS */
$blue-primary: #1D4681;
$blue-primary-faded: #335d93;
$blue-light: #00a3de;
$blue-light-bright: #29abe1;
$blue-dark: #4c7fab;
$blue-dark-muted: rgba(124, 181, 236, 0.5);
$blue-light-faded: #d9e3f7;
$teal: #00a99d;
$green-primary: #7ebc71;
$green-primary-faded: rgba(126, 188, 113, .5);
$green-dark: #006937;
$green-dark-faded: rgba(0, 105, 55, .5);
$white: #ffffff;
$black: #000000;
$charcoal: #333333;
$orange: #f79646;
$orange-light-faded: rgba(247, 191, 144, .2);
$red: #c22311;
$red-muted: #d34853;
$border-color-dark: #dee2eb;
$purple: #932F6D;

$icon-color-codes: (
    fa-red: $red,
    fa-orange: $orange,
    fa-blue: $blue-light,
    fa-green: $green-primary,
    fa-dark-purple: $purple,
    fa-red-muted: $red-muted
) !default;

@function color-code($name, $colors: $icon-color-codes) {
    @return if($name == null, "", "-#{$name}");
}

/* GRAYS - Rima's Blue-Gray */
$gray: rgba(173, 183, 204, 1); // #adb7cc;
$gray-100: scale-color($gray, $lightness: 80%); // #eff1f5
$gray-150: scale-color($gray, $lightness: 70%);
$gray-200: scale-color($gray, $lightness: 60%); // #dee2eb
$gray-250: scale-color($gray, $lightness: 50%);
$gray-300: scale-color($gray, $lightness: 40%); // #ced4e0
$gray-350: scale-color($gray, $lightness: 30%);
$gray-400: scale-color($gray, $lightness: 20%); // #bdc5d6
$gray-450: scale-color($gray, $lightness: 10%);
$gray-500: $gray; // #adb7cc
$gray-550: scale-color($gray, $lightness: -10%);
$gray-600: scale-color($gray, $lightness: -20%); // #7f8eaf
$gray-650: scale-color($gray, $lightness: -30%);
$gray-700: scale-color($gray, $lightness: -40%); // #57688b
$gray-750: scale-color($gray, $lightness: -50%);
$gray-800: scale-color($gray, $lightness: -60%); // #3a455d
$gray-850: scale-color($gray, $lightness: -70%);
$gray-900: scale-color($gray, $lightness: -80%); // #1d232e
$gray-950: scale-color($gray, $lightness: -90%);

/** BORDERS AND BACKGROUNDS **/
$background-color: scale-color($gray-100, $lightness: 50%);
$background-color-dark: $gray-100;
$border-color: scale-color($gray-200, $lightness: 30%);
$border-color-dark: $gray-200;
$box-shadow-color: $gray-400;

/** TEXT **/
$text-color: $charcoal;
$text-color-muted: $gray-700;
$text-color-disabled: $gray-500;

/** LINKS **/
/* Link interactions (such as hover delay) are in main _variables file */
$link-color: $blue-light;
$link-color-hover: $blue-primary;

/** VALIDATION **/
$error-border-color: $red;
$error-text-color: $red;
$error-background-color: scale-color($red, $lightness: 90%); //#f8b4ad
$warning-text-color: #fce935;
$success-text-color: $green-primary;
$deactivated: rgba(240, 128, 128, .2);

$transparent-error-color: #c2231199;
$transparent-warning-color: #fce93599;
$transparent-success-color: #61c25099;

// Var() set up
:root {
    // General
    --red-muted: #d34853;
    --color-white: #ffffff;
    --status-color-unknown: #f7f8fa;
    --status-color-active: #7ebc71;
    --status-color-upcoming: #B7E4FF;
    --status-color-completed: #335C88;
    --status-color-deferred: #ff9c5c;
    --status-color-canceled: var(--red-muted);

  // Chips
    --chip-selected-color: #A9A9A9;
    --chip-text-color: #000000;

    /* GRAYS - Rima's Blue-Gray */
    --gray-300: $gray-300;
    --gray-700: $gray-700;
    --regular-blue-button: #1D4681;

    // Travel Calendar
    --tc-conflict-red: #c22331;
    --tc-conflict-red-background: #ff8072;
    --mat-list-hover: #ebe9ed;

    // mdc-button colors
    --button-background-disabled: #ffffff;
    --button-border-disabled: #e8ebf1;
    --button-text-disabled: #57688b;

    // Fonts
    --default-font-size: 1em;
}
