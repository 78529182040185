/* width */
::-webkit-scrollbar {
    width: 17px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 1px 0 6px $gray-100;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $gray-300;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $gray-400;
}