/*************************/
@use '@angular/material' as mat;
/*** GLOBAL CSS STYLES ***/
/*************************/

/* All global CSS passes through this file as @imports and can be managed here. */
/* This and FontAwesome should be the only CSS/SCSS files referenced in angular.json. */

/*************************/

/** MATERIAL THEME **/

//Material theming mixins
@import '@angular/material';

//Fonts. Pulls in font files, then passes custom font family to Material theme generator
@import "_fonts.scss";

//Material color palettes, generated off of GTN base colors using http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5
@import '_palettes.scss';

//Defines font family, primary, accent and warning colors, using color palettes from _palettes.scss
@import 'gtn-theme.scss';

//Apply custom theme
@include mat.all-component-themes($gtn-theme);
/** END MATERIAL THEME **/

/** BEGIN REST OF GLOBAL STYLE **/

//Other colors and useful variables. Can also use this to override BS4 variables prior to BS4 styles being generated.
@import "_variables.scss";

//Bootstrap 4 layout grid and spacing utilities
@import 'layout-styles/functions';
@import 'layout-styles/variables';
@import 'layout-styles/mixins';
@import 'layout-styles/reboot';

/** BEGIN MATERIAL OVERRIDES **/

//@import 'material-overrides.scss';
@import 'assets/layouts/template-views';
@import 'assets/layouts/mdc-button-layout';
@import 'assets/layouts/mdc-controls';
/** END MATERIAL OVERRIDES **/

//BS4 reboot overrides
hr {
    border: none;
    background: $border-color;
    margin: 15px 0;
    height: 1px;
}

button,
a {
    &:focus {
        outline: none !important;
    }
}

//Easy reference grid sizes
$breakpoint-xs: map-get($grid-breakpoints, "xs");
$breakpoint-sm: map-get($grid-breakpoints, "sm");
$breakpoint-md: map-get($grid-breakpoints, "md");
$breakpoint-lg: map-get($grid-breakpoints, "lg");
$breakpoint-xl: map-get($grid-breakpoints, "xl");


@import 'layout-styles/bootstrap-grid.scss';
//@import 'layout-styles/forms';
@import 'layout-styles/utilities';

//ag-grid styles
@import "../../../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/styles/ag-theme-balham.css";
@import "../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";


//this is how to customize the theme for ag-grid
.ag-theme-balham {
    --ag-background-color: white;
    --ag-border-color: #eff1f5;
    --ag-header-background-color: white;
    --ag-header-foreground-color: #4c7fab;
    --ag-secondary-foreground-color: #333333;
    --ag-foreground-color: #333333;
    --ag-font-size: 14px;
    --ag-row-height: 38px;
    --ag-card-shadow: none;
    --ag-odd-row-background-color: rgb(242, 242, 242, 0.4);
    --ag-row-border-color: none;

    .ag-root-wrapper {
        height: 100%;
    }

    .ag-cell {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .ag-header-cell {
        font-size: 14px;
    }

    .clickable:hover {
        cursor: pointer;
    }

    .copy-paste-cell:hover {
        font-weight: bold;
        border: 1px solid black;
    }

    .disabled-row {
        color: #d9d9d9;
        font-style: italic;
    }

    .pending-edit {
        background-color: rgba(97, 194, 80, .4);
        border-color: #d1d1d1;
    }

    .pending-delete {
        background-color: rgba(240, 128, 128, .4);
        border-color: #d1d1d1;
    }

    .pending-add {
        background-color: rgba(0, 163, 222, .4);
        border-color: #d1d1d1;
    }

    .bold-cell {
        font-weight: 700;
    }

    .drag-hover {
        background-color: #e5e5ff;
    }

    .far.fa-file-word {
        color: #335c88;
    }

    .far.fa-file-excel {
        color: #50a572;
    }

    .far.fa-file-pdf {
        color: $danger;
    }

    .center-cell {
        text-align: center;
    }

    .regular-text-color {
        color: $charcoal !important;
    }

    .bold {
        font-weight: 700;
    }

    .icon-cell {
        color: $blue-primary;
        cursor: pointer;
    }
}

.ag-theme-balham.folders-grid {
    --ag-background-color: white;
    --ag-border-color: #eff1f5;
    --ag-font-size: 14px;
    --ag-odd-row-background-color: white;
    --ag-foreground-color: #333333;

    .ag-root-wrapper {
        border: 0;
        height: 100%;
    }

    --ag-selected-row-background-color: white !important;
}

//GTN utilities that might be extended by other components
@import '_type-utilities';
@import '_display-utilities';


/** BASIC SETTINGS **/

html {
    font-size: 16px;
}

body {
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    line-height: 1.42857143rem;
    color: $text-color;
    overflow: hidden;
}

/* OTHER SECTIONS */
@import '_headers';
@import '_scroll-bar';
@import '_grid';
@import '_form';
@import '_links';
@import '_modals';
@import '_navs';

/** LAYOUT **/
.sidenav-item {
    color: $blue-primary;
    display: flex;
    flex-direction: row;
    height: $nav-height;
    overflow: hidden;
    font-size: 16px;

    &.link-active {
        background-color: $gray-200;
    }

    &:hover:not(.link-active) {
        background-color: $background-color !important;
    }

    &.mat-expansion-panel-header {
        font-size: 16px;
    }
}

.sidenav-expanded-background {
    background-color: $gray-100;
}

.sidenav-subitem {
    color: $blue-primary;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 40px;
    padding: 10px;

    i {
        font-size: 23px;
        align-items: center;
        display: flex;
        height: 34px;
        justify-content: center;
        width: 60px;
    }

    .sidenav-subitem-title {
        font-size: 14px;
        align-items: center;
        display: flex;
        height: 35px;
        justify-content: left;
        width: 500px;
    }

    &.link-active {
        background-color: $gray-200;
    }

    &:hover:not(.link-active) {
        background-color: $background-color !important;
    }
}

.sidenav-accordion {
    .mat-expansion-panel-body {
        padding: 0 24px;
    }
}

.sidenav-box {
    align-items: center;
    display: flex;
    height: $nav-height;
    justify-content: center;
    width: $nav-height;
}

.sidenav-expanded {
    display: flex;
    flex: 1;
    height: $nav-height;
    min-width: 0;
    white-space: nowrap;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* Component sections */
.component-wrap {
    margin-bottom: $component-margin-bottom;

    .timeline-wrap {
        margin-bottom: 50px;
    }
}

.white-backdrop {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
}

.blue-primary {
    color: $blue-primary;
}

.blue-primary-faded {
    color: $blue-primary-faded;
}

.success-color {
    color: $success-text-color;
}

@each $color in map-keys($icon-color-codes) {
    .#{$color} {
        color: map-get($icon-color-codes, $color);
    }
}

/*** SPECIFIC COMPONENTS NOT HOUSED IN SEPARATE FILES ***/

/** TOOLS MENU **/
.tools-menu {
    //button that goes next to page-top-header
    display: inline-block;
    padding-right: 15px;
    vertical-align: middle;
    position: relative;
    margin-left: 15px;
}

.error-message {
    color: $danger;
    font-weight: bold;
}

// Gtn controls error message

.pointer {
    cursor: pointer !important;
}

.grab {
    cursor: grab !important;
}

.hide {
    display: none;
}


// drop number spinner from number inputs
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

//snackbar

.gtn-toast {
    background-color: white !important;
    color: $blue-primary !important;
}
