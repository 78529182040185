
$gtn-blue: (
    50 : #e0e7ef,
    100 : #b3c2d7,
    200 : #809abc,
    300 : #4d71a1,
    400 : #26528c,
    500 : #003478,
    600 : #002f70,
    700 : #002765,
    800 : #00215b,
    900 : #001548,
    A100 : #7c93ff,
    A200 : #496aff,
    A400 : #1640ff,
    A700 : #002dfc,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$gtn-light-blue: (
    50 : #e0f4fb,
    100 : #b3e3f5,
    200 : #80d1ef,
    300 : #4dbfe8,
    400 : #26b1e3,
    500 : #00a3de,
    600 : #009bda,
    700 : #0091d5,
    800 : #0088d1,
    900 : #0077c8,
    A100 : #f1f8ff,
    A200 : #bee1ff,
    A400 : #8bc9ff,
    A700 : #71beff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gtn-green: (
    50 : #ecf8ea,
    100 : #d0edcb,
    200 : #b0e1a8,
    300 : #90d485,
    400 : #79cb6a,
    500 : #61c250,
    600 : #59bc49,
    700 : #4fb440,
    800 : #45ac37,
    900 : #339f27,
    A100 : #e2ffdf,
    A200 : #b3ffac,
    A400 : #85ff79,
    A700 : #6eff5f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gtn-orange: (
    50 : #fef2e9,
    100 : #fde0c8,
    200 : #fbcba3,
    300 : #f9b67e,
    400 : #f8a662,
    500 : #f79646,
    600 : #f68e3f,
    700 : #f58337,
    800 : #f3792f,
    900 : #f16820,
    A100 : #ffffff,
    A200 : #fff8f5,
    A400 : #ffd5c2,
    A700 : #ffc3a8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gtn-gray: (
    50 : #f6f6f6,
    100 : #e8e8e8,
    200 : #d9d9d9,
    300 : #c9c9c9,
    400 : #bebebe,
    500 : #b2b2b2,
    600 : #ababab,
    700 : #a2a2a2,
    800 : #999999,
    900 : #8a8a8a,
    A100 : #ffffff,
    A200 : #fdeeee,
    A400 : #ffb9b9,
    A700 : #ffa0a0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gtn-red: (
    50 : #f8e5e2,
    100 : #edbdb8,
    200 : #e19188,
    300 : #d46558,
    400 : #cb4435,
    500 : #c22311,
    600 : #bc1f0f,
    700 : #b41a0c,
    800 : #ac150a,
    900 : #9f0c05,
    A100 : #ffcbca,
    A200 : #ff9997,
    A400 : #ff6764,
    A700 : #ff4e4b,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

