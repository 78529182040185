/** GLOBAL NAV STYLES **/

/** TOOLBARS **/
#moduleNavbar {
    background: $gray-200;
    border-radius: 0;
    border: none !important;
    top: 49px;
    height: 49px;
    margin-bottom: 0;
    z-index: $moduleNavbar-zindex;

    #moduleNav {

        a {
            color: $text-color;
            background: none;
            border-radius: 0;
            height: 49px;
            line-height: 49px;

            &:hover {
                background: scale-color($gray-200, $lightness: -10%) !important;
                transition: $link-hover-delay;
            }

            &.link-active {
                background: scale-color($gray-200, $lightness: -20%);
            }
        }
    }
}


/** BREADCRUMBS **/
.title-with-breadcrumb {

    &.horizontal-align-breadcrumb {
        margin-top: -53px !important;
        margin-left: 85px;
        margin-bottom: 26px !important;
    }

    .breadcrumb {

        span,
        a,
        gtn-sponsor-name {
            vertical-align: middle;
            font-size: 1rem;
            display: inline-block;

        }

        .breadcrumb-separator {
            margin: 0 10px;
            color: $gray-400;
            font-size: .8rem;
            display: inline-block;
            margin-top: 3px;
        }
    }
}




/** RETURNS **/
.return {
    display: inline-block;
    margin-bottom: 15px;

    i {
        cursor: pointer;
    }

    i {
        font-size: 1.0625rem;
        margin-right: 10px;
    }

    a,
    span {
        font-size: 1.125rem;
    }

    span {
        color: $link-color;
        cursor: pointer;
    }

    span:hover {
        color: $link-color-hover;
    }
}
