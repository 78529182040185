@import 'assets/styles/colors.scss';

.form-button-group {
    padding: 11.5px 30px;
    border-top: solid 1px $border-color;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: $form-button-group-zindex;

    gtn-checkbox {
        display: inline-block;
        margin-left: 10px;
    }
}


body {
    .mat-mdc-unelevated-button {
        font-family: "Proxima Nova", Helvetica, sans-serif;
        font-size: 16px;
        letter-spacing: normal;
        font-weight: 500;
        text-transform: none;
        height: 36px;
        border-radius: 4px;
        padding: 0 16px;
        min-width: 64px;
        line-height: 36px;
        box-shadow: rgba(0, 0, 0, 0.2) 0 0 0 0, rgba(0, 0, 0, 0.14) 0 0 0 0, rgba(0, 0, 0, 0.12) 0 0 0 0;
        color: #57688b;
        border: 1px solid #e8ebf1;
    }

    .mdc-fab--mini {
        width: 34px;
        height: 34px;
    }
}
