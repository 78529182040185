/** FONT DEFINITIONS **/
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-BoldIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-RegularIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Th';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-Extrabld.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://info.gtn.com/hubfs/_2019_refresh/fonts/ProximaNova-SemiboldIt.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}